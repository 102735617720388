import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'Components/Base/Container';
import Loading from 'Components/Loading';
import { Helmet } from 'react-helmet';

import NeedUserInfoOrder, { UserInfoFormDataOnSubmit } from './Components/NeedUserInfoOrder';
import OrderDetails from './Containers/OrderDetails';
import OrderChantConfirm from './Containers/OrderChantConfirm';

import {
  useClientGetOrderData,
  useClientConfirmChantMessage,
  useClientUpdateChantMessage,
  useUpdateFixBadLuckOrder,
  useUpdateSingleShipmentOrder,
  useUpdateWallpaperOrder,
  useUpdateWreathOrder,
  useUpdateRMSWallpaperOrder,
  useUpdateMTWWallpaperOrder,
  useUpdateWishVowOrder,
  useUpdateFortuneTellingOrder,
} from 'Domain/Orders/hooks';
import {
  ORDER_STATUS,
  ORDER_TYPE,
  SatthaOrder,
  SkuWallpaperData,
  SKU_CHANT_MODE,
} from 'Libs/Firebase/firestore/Order';
import { CHANT_MODE, getChantText, TEXT_LABEL } from 'Domain/Sattha/Chant';
import { Box, Button, Typography } from 'Components/Base';
import {
  Backdrop,
  BackdropProps,
  Checkbox,
  FormControlLabel,
  Modal,
  Divider,
  Card,
  CardContent,
  Paper,
  Alert,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import OrderDataConfirmation from './Containers/OrderDataConfirmation';
import { CheckBox } from '@mui/icons-material';
import PackageInfoForm from 'Components/PackageInfoForm';
import { useConfig } from 'Libs/Config';
import { Footer } from './Components/OrderDetails/OrderDetails';
import MarkdownBox from 'Components/MarkdownBox';
import OrderGiftDetails from './Containers/OrderGiftDetails';

import StateTitle from './Components/StateTitle';
import { THEME_VARIANT } from 'Components/Base/Theme/themeConfigs';

enum OrderUIState {
  INITIALIZING,
  NEED_USER_INFO,
  GIFT_INIT,
  CARD_CONFIRMATION,
  ORDER_DETAILS,
  ERROR_NOT_SUPPORT_TYPE,
  ERROR,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: '#fff',
    // backgroundColor: 'rgba(255,255,255,0.90)',
    backgroundColor: 'rgba(20,20,20,0.8)',
  },
}));

function WhiteBackdrop(props: BackdropProps) {
  const classes = useStyles();
  return <Backdrop className={classes.backdrop} {...props} />;
}

export const isSingleShipmentOrder = (order: SatthaOrder) => {
  return order.type === ORDER_TYPE.SHIPMENT && !order.bundleInfo;
};

enum CHANT_CONFIRM_UI_MODE {
  IMAGE,
  DETAIL,
}

const getChantConfirmationUIMode = (orderInfo: SatthaOrder) => {
  const { skuMode, skuMasterCode } = orderInfo;
  if (isSingleShipmentOrder(orderInfo)) return CHANT_CONFIRM_UI_MODE.DETAIL;
  if (
    [
      SKU_CHANT_MODE.FIX_BADLUCK,
      SKU_CHANT_MODE.WISH,
      SKU_CHANT_MODE.VOW,
      SKU_CHANT_MODE.MERIT,
    ].includes(skuMode)
  ) {
    return CHANT_CONFIRM_UI_MODE.IMAGE;
  }
  return CHANT_CONFIRM_UI_MODE.DETAIL;
};

export default function ClientOrderContainer({ clientOrderId }: { clientOrderId: string }) {
  const config = useConfig();
  const [uiState, setUiState] = useState(OrderUIState.INITIALIZING);
  const [isLoading, setIsLoading] = useState(true);
  const { loading, error, data, refetch } = useClientGetOrderData(clientOrderId);

  const { confirmChantMessage } = useClientConfirmChantMessage(clientOrderId);

  const handleUserInfoUpdate = async (userInfo: UserInfoFormDataOnSubmit) => {
    setUiState(OrderUIState.CARD_CONFIRMATION);
  };

  const handleEditChantMessage = async () => {
    setUiState(OrderUIState.NEED_USER_INFO);
  };

  const handleConfirmChantMessage = async () => {
    await confirmChantMessage();
    await refetch();
    setUiState(OrderUIState.ORDER_DETAILS);
  };

  const handleGiftSwitcher = async () => {
    if (uiState === OrderUIState.GIFT_INIT) {
      setUiState(OrderUIState.NEED_USER_INFO);
    } else if ([OrderUIState.NEED_USER_INFO].includes(uiState)) {
      setUiState(OrderUIState.GIFT_INIT);
    }
  };

  useEffect(() => {
    if (loading === false && data) {
      if (
        data.type === ORDER_TYPE.SHIPMENT &&
        data.bundleInfo &&
        ![SKU_CHANT_MODE.WALLPAPER, SKU_CHANT_MODE.WREATH].includes(data.skuMode)
      ) {
        setUiState(OrderUIState.ERROR_NOT_SUPPORT_TYPE);
      } else {
        switch (data.status) {
          // TODO: Check Correct Order Status
          case ORDER_STATUS.CLIENT_FILLED_INFO:
            if (data.chantConfirmed) {
              setUiState(OrderUIState.ORDER_DETAILS);
            } else {
              setUiState(OrderUIState.CARD_CONFIRMATION);
            }
            break;
          case ORDER_STATUS.MERCHANT_ACCEPT:
          case ORDER_STATUS.MERCHANT_PREPARING:
          case ORDER_STATUS.MERCHANT_DELIVERING:
          case ORDER_STATUS.MERCHANT_DELIVERED:
          case ORDER_STATUS.MESSAGE_FAIL_DELIVERING:
          case ORDER_STATUS.CLIENT_ACCEPT:
          case ORDER_STATUS.COMPLETED:
            setUiState(OrderUIState.ORDER_DETAILS);
            break;
          case ORDER_STATUS.PAID:
            setUiState(OrderUIState.NEED_USER_INFO);
            break;
          case ORDER_STATUS.CANCELLED:
            setUiState(OrderUIState.ERROR);
            break;
          case ORDER_STATUS.CREATED:
          case ORDER_STATUS.PENDING:
          case ORDER_STATUS.PAYMENT_UPLOADED:
            setUiState(OrderUIState.INITIALIZING);
            break;
          case ORDER_STATUS.FOLLOW_UP:
          case ORDER_STATUS.WAIT_FOR_FOLLOW_UP:
          default:
            setUiState(OrderUIState.NEED_USER_INFO);
            break;
        }
      }
      setIsLoading(false);
    } else if (loading === false) {
      setUiState(OrderUIState.ERROR);
      setIsLoading(false);
    }

    return;
  }, [loading, error, data]);

  console.log(loading, error, data);

  const mode = data?.skuMode;

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>ออเดอร์ของคุณ</title>
        </Helmet>
        <Container maxWidth={'sm'}>
          {isLoading && (
            <Box mt={5}>
              <Loading />
            </Box>
          )}
        </Container>
      </>
    );
  }

  if (!data || error) {
    return (
      <>
        <Helmet>
          <title>ออเดอร์ของคุณ</title>
        </Helmet>
        <Container maxWidth={'sm'}>
          {error?.response.status === 401 && (
            <Box mt={5}>
              <Box mt={5}>
                ออเดอร์ของคุณถูกผูกกับผู้ใช้คนอื่นแล้ว กรุณาติดต่อ{' '}
                <a href="mailto:hello@sattha.online">hello@sattha.online</a> เพื่อสอบถามเพิ่มเติม
              </Box>
            </Box>
          )}
          {error?.response.status !== 401 && (
            <Box mt={5}>
              <Box mt={5}>
                มีข้อผิดพลาด กรุณาติดต่อ{' '}
                <a href="mailto:hello@sattha.online">hello@sattha.online</a> เพื่อสอบถามเพิ่มเติม
              </Box>
            </Box>
          )}
        </Container>
      </>
    );
  }

  const chantConfirmationUIMode = getChantConfirmationUIMode(data);

  // TODO: Check if owner, if receiver, do not show checkbox
  const showGiftButton =
    config.gift.enableGift &&
    [OrderUIState.NEED_USER_INFO, OrderUIState.GIFT_INIT].includes(uiState);
  const isGiftSelected = [OrderUIState.GIFT_INIT].includes(uiState);

  const shouldShowBackgroundImage = config.theme ? config.theme.backgroundImage : null;

  return (
    <>
      <Helmet>
        {mode &&
          [OrderUIState.NEED_USER_INFO, OrderUIState.CARD_CONFIRMATION].includes(uiState) && (
            <title>{getChantText(mode)[TEXT_LABEL.CLIENT_USER_INFO_TITLE]}</title>
          )}
        {mode && uiState === OrderUIState.ORDER_DETAILS && (
          <title>{getChantText(mode)[TEXT_LABEL.CLIENT_USER_CARD_TITLE]}</title>
        )}
      </Helmet>
      <Box
        py={5}
        px={{ xs: 1, sm: 5 }}
        sx={(theme) => ({
          backgroundImage: !!shouldShowBackgroundImage
            ? `url('${config.theme.backgroundImage}'), ${theme.palette.background.paper}`
            : null,
          backgroundRepeat: 'repeat',
        })}
      >
        <Container maxWidth={config.theme.variant === THEME_VARIANT.THAIRATH ? 'md' : 'sm'}>
          {[OrderUIState.NEED_USER_INFO, OrderUIState.CARD_CONFIRMATION].includes(uiState) && (
            <>
              {config.theme.variant === THEME_VARIANT.THAIRATH ? (
                <>
                  <StateTitle title="กรอกข้อมูลการขอพร" />
                  <Box
                    display="grid"
                    rowGap={2}
                    sx={(theme) => ({
                      maxWidth: 600,
                      margin: '0 auto',
                      backgroundColor: theme.palette.common.white,
                      borderRadius: theme.spacing(4),
                    })}
                    p={{ xs: 2, sm: 5 }}
                  >
                    <PackageInfoForm orderInfo={data} />
                    {showGiftButton && (
                      <Box my={2} mx={2}>
                        <Alert icon={false} severity="success">
                          <FormControlLabel
                            label="🎁 ส่งชุดนี้เป็นของขวัญให้เพื่อน"
                            control={
                              <Checkbox checked={isGiftSelected} onChange={handleGiftSwitcher} />
                            }
                          />
                        </Alert>
                      </Box>
                    )}
                    <NeedUserInfoOrder
                      onUserInfoUpdate={handleUserInfoUpdate}
                      orderInfo={data}
                      fillInitialData={data.chantMessage !== undefined}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box my={2}></Box>
                  <PackageInfoForm orderInfo={data} />
                  <Box my={2}></Box>
                  <Divider />
                  {showGiftButton && (
                    <Box my={2} mx={2}>
                      <Alert icon={false} severity="success">
                        <FormControlLabel
                          label="🎁 ส่งชุดนี้เป็นของขวัญให้เพื่อน"
                          control={
                            <Checkbox checked={isGiftSelected} onChange={handleGiftSwitcher} />
                          }
                        />
                      </Alert>
                    </Box>
                  )}
                  <NeedUserInfoOrder
                    onUserInfoUpdate={handleUserInfoUpdate}
                    orderInfo={data}
                    fillInitialData={data.chantMessage !== undefined}
                  />
                </>
              )}
            </>
          )}
          {[OrderUIState.GIFT_INIT].includes(uiState) && (
            <>
              <Box my={2}></Box>
              <PackageInfoForm orderInfo={data} />
              <Box my={2}></Box>
              <Divider />
              {showGiftButton && (
                <>
                  <Box my={2} mx={2}>
                    <Alert icon={false} severity="success">
                      <FormControlLabel
                        label="🎁 ส่งชุดนี้เป็นของขวัญให้เพื่อน"
                        control={
                          <Checkbox checked={isGiftSelected} onChange={handleGiftSwitcher} />
                        }
                      />
                    </Alert>
                    <OrderGiftDetails orderInfo={data} />
                  </Box>
                </>
              )}
            </>
          )}
          {uiState === OrderUIState.CARD_CONFIRMATION && (
            <Modal
              open={true}
              BackdropComponent={WhiteBackdrop}
              style={{
                display: 'flex',
                alignItems:
                  chantConfirmationUIMode === CHANT_CONFIRM_UI_MODE.IMAGE ? 'center' : undefined,
                justifyContent: 'center',
                overflow: 'scroll',
                // position: 'absolute' as 'absolute',
                // top: '50%',
                // left: '50%',
                // transform: 'translate(-50%, -50%)',
              }}
            >
              <Container maxWidth={'sm'}>
                <Box p={0}>
                  {chantConfirmationUIMode === CHANT_CONFIRM_UI_MODE.IMAGE && (
                    <OrderChantConfirm
                      orderId={data?.orderId}
                      id={data.id}
                      onEditChantMessage={handleEditChantMessage}
                      onConfirmChantMessage={handleConfirmChantMessage}
                    />
                  )}
                  {chantConfirmationUIMode === CHANT_CONFIRM_UI_MODE.DETAIL && (
                    <OrderDataConfirmation
                      orderId={data?.orderId}
                      onEditChantMessage={handleEditChantMessage}
                      onConfirmChantMessage={handleConfirmChantMessage}
                    />
                  )}
                </Box>
              </Container>
            </Modal>
          )}
        </Container>

        {uiState === OrderUIState.ORDER_DETAILS && (
          <>
            {config.theme.variant === THEME_VARIANT.THAIRATH ? (
              <>
                <StateTitle title="ขอพร และหลักฐานการขอพร" />
                <Box display="flex" gap={2} flexDirection="column">
                  <Box
                    sx={(theme) => ({
                      maxWidth: 600,
                      margin: '0 auto',
                      boxSizing: 'border-box',
                      width: '100%',
                    })}
                  >
                    <PackageInfoForm orderInfo={data} />
                  </Box>
                  <Box
                    display="grid"
                    rowGap={2}
                    sx={(theme) => ({
                      maxWidth: 600,
                      margin: '0 auto',
                      backgroundColor: theme.palette.common.white,
                      borderRadius: 8,
                      boxSizing: 'border-box',
                    })}
                    p={{ xs: 2, sm: 5 }}
                  >
                    <OrderDetails
                      orderId={data.orderId}
                      id={data.id}
                      status={data.status}
                      locationId={data.locationId}
                      locationHolyThingImageUrl={data.locationHolyThingImageUrl}
                      mode={data.skuMode}
                      haveShippingData={data.bundleInfo?.haveShippingOrder}
                      shippingData={data.bundleInfo?.shippingOrder?.shippingData}
                      shippingTrackingData={data.bundleInfo?.shippingOrder?.shippingTrackingData}
                      orderInfo={data}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <OrderDetails
                orderId={data.orderId}
                id={data.id}
                status={data.status}
                locationId={data.locationId}
                locationHolyThingImageUrl={data.locationHolyThingImageUrl}
                mode={data.skuMode}
                haveShippingData={data.bundleInfo?.haveShippingOrder}
                shippingData={data.bundleInfo?.shippingOrder?.shippingData}
                shippingTrackingData={data.bundleInfo?.shippingOrder?.shippingTrackingData}
                orderInfo={data}
              />
            )}
          </>
        )}
      </Box>
      {uiState === OrderUIState.ERROR_NOT_SUPPORT_TYPE && (
        <Container maxWidth={'sm'}>
          <Box mt={5}>ออเดอร์ของคุณไม่ถูกต้อง</Box>
        </Container>
      )}
    </>
  );
}
