import dayjs from 'dayjs';
import { Paper, styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { OrderInfo } from 'Domain/Orders/types';
import { Box, Typography } from 'Components/Base';
import { SatthaOrder } from 'Libs/Firebase/firestore/Order';
import OrderInfoDetails from './OrderInfoDetails';

import { THEME_VARIANT } from 'Components/Base/Theme/themeConfigs';
import { useConfig } from 'Libs/Config';

type PackageInfoFormProps = {
  orderInfo: SatthaOrder;
  hidePrice?: boolean;
};

const StyledPaper = styled((props) => (
  <Paper
    elevation={2}
    sx={(theme) => ({
      paddingY: { xs: 2, sm: 3 },
      paddingBottom: { xs: 2, sm: 5 },
      paddingX: 3,
      background: theme.palette.common.white,
    })}
    {...props}
  />
))(
  ({ theme }) => `
    border-radius: ${theme.spacing(4)};
  `
);

const StyledContentBox = styled((props) => (
  <Box
    sx={{
      grid: {
        xs: '100% / minmax(30px, 50px) 1fr',
        sm: '100% / minmax(auto, 90px) 1fr',
        md: '100% / minmax(auto, 165px) 1fr',
      },
    }}
    {...props}
  />
))(
  ({ theme }) => `
    display: grid;
    gap: ${theme.spacing(2)};
  `
);

export default function PackageInfoForm(props: PackageInfoFormProps) {
  const config = useConfig();
  const theme = useTheme();
  const isMinWidthSM = useMediaQuery(theme.breakpoints.up('sm'));
  const { orderInfo, hidePrice } = props;
  const {
    skuName,
    locationName,
    skuDate = new Date(),
    refId,
    paymentAmount = 0.0,
    skuImageUrl,
    packageDate,
    serviceDate,
  } = orderInfo;

  const formattedPrice = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(paymentAmount);

  let dateStr: string = 'ยังไม่ได้เลือกวัน';
  if (serviceDate) dateStr = 'วันที่: ' + dayjs(serviceDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
  else if (packageDate) dateStr = 'วันที่: ' + packageDate;

  const orderInfoDetails = [
    {
      label: 'ชื่อแพคเกจ',
      value: skuName,
    },
    {
      label: 'ชื่อสิ่งศักดิ์สิทธิ์',
      value: locationName,
    },
    {
      label: 'วันที่ซื้อ',
      value: dateStr,
    },
    {
      label: 'เลขออเดอร์',
      value: refId,
    },
  ];

  if (config.theme.variant === THEME_VARIANT.THAIRATH) {
    return (
      <StyledPaper>
        <Typography variant="h3" mb={3} sx={{ fontSize: { xs: 16, sm: 20 } }}>
          ข้อมูลการสั่งซื้อ
        </Typography>
        <StyledContentBox>
          {!!skuImageUrl && (
            <img width="100%" src={skuImageUrl} alt={locationName} style={{ objectFit: 'cover' }} />
          )}
          <Box display="flex" flexDirection="column" gap="12px">
            {orderInfoDetails.map(({ label, value }, index) => {
              return <OrderInfoDetails key={index} label={label} value={value} />;
            })}
            {isMinWidthSM && <OrderInfoDetails label="ราคา" value={formattedPrice} />}
          </Box>
        </StyledContentBox>
        {!isMinWidthSM && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            mt={2}
            sx={{ borderTop: '1px solid #D9DBE9' }}
          >
            <Typography sx={{ fontWeight: 650, color: '#49454F', fontSize: 12 }}>ราคา</Typography>
            <Typography
              sx={{
                fontWeight: 800,
                color: '#8C46FF',
                fontSize: 20,
                wordBreak: 'break-word',
              }}
            >
              {formattedPrice}
            </Typography>
          </Box>
        )}
      </StyledPaper>
    );
  }

  return (
    <Paper elevation={0}>
      <Box display="flex" flexDirection="row" mx={2}>
        <Box width={78} height={78}>
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={skuImageUrl}
            alt="package name"
          />
        </Box>
        <Box display="flex" flexDirection="column" ml={2}>
          <Typography variant="h4">{skuName}</Typography>
          <Box mt={1}></Box>
          <Typography variant="label">{locationName}</Typography>
          <Typography variant="label">{dateStr}</Typography>
          <Typography variant="label">Order Number: {refId}</Typography>
          {!hidePrice && (
            <>
              <Box my={1}></Box>
              <Typography variant="title">{formattedPrice}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
