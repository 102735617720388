import React from 'react';
import { Divider, Card } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

import { Box, Button, Container, Typography } from 'Components/Base';

import ChantImageBox from './Components/ChantImageBox';
import StoreUploadedImagesBox, { Attachment } from './Components/StoreUploadedImagesBox';
import Loading from 'Components/Loading';
import ChantInstructions from 'Components/Locations/ChantInstructions';
import { CHANT_MODE, getChantText, TEXT_LABEL } from 'Domain/Sattha/Chant';
import ImageBox from './Components/ImageBox';
import ShippingDetailsBox, { OrderShippingDataProps } from './Components/ShippingDetailsBox';
import PackageInfoForm from 'Components/PackageInfoForm';
import { SatthaOrder, SKU_CHANT_MODE } from 'Libs/Firebase/firestore/Order';
import WallpaperCampaign from 'Containers/WallpaperCampaign';
import NotifyLateOrder, { LateAlertBoxUtils } from '../LateAlertBox';
import WallpaperSummaryCard from 'Components/WallpaperSummaryCard';
import WreathSummaryCard from 'Components/WreathSummaryCard';
import { isSingleShipmentOrder } from 'Routes/ClientOrder';
import ShipmentSummaryCard from 'Components/ShipmentSummaryCard';
import OrderWaitBox from 'Containers/OrderWaitBox';
import OrderDetailsFortuneTelling from './Components/OrderDetailsFortuneTelling';
import { STORE_LOCATIONS } from 'Components/Locations/location';

const FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_URL;
const FEEDBACK_URL_FTL = process.env.REACT_APP_FEEDBACK_URL_FTL;
const ORDER_ID_ENTRY_FIELD = process.env.REACT_APP_FEEDBACK_URL_ORDER_ID_FIELD;
const ORDER_ID_FTL_ENTRY_FIELD = process.env.REACT_APP_FEEDBACK_URL_FTL_ORDER_ID_FIELD;

export interface OrderDetailsProps {
  chantImageUrl?: string;
  storeUploadImageList?: Attachment[];
  showFeedbackButton?: boolean;
  feedbackUrl?: string;
  isChantImageLoading?: boolean;
  locationId: string;
  mode: CHANT_MODE;
  locationHolyThingImageUrl?: string;
  haveShippingData?: boolean;
  shippingData?: OrderShippingDataProps;
  shippingTrackingData?: any;
  orderInfo: SatthaOrder;
}

const getFeedbackUrl = (mode?: SKU_CHANT_MODE, orderId?: string) => {
  let url = FEEDBACK_URL;
  switch (mode) {
    case SKU_CHANT_MODE.FORTUNE_TELLING_LIVE:
      url = FEEDBACK_URL_FTL;
      if (orderId && ORDER_ID_FTL_ENTRY_FIELD) {
        url = `${url}?entry.${ORDER_ID_FTL_ENTRY_FIELD}=${orderId}`;
      }
      break;
    default:
      url = FEEDBACK_URL;
      if (orderId && ORDER_ID_ENTRY_FIELD) {
        url = `${url}?entry.${ORDER_ID_ENTRY_FIELD}=${orderId}`;
      }
      break;
  }
  return url;
};

export const Footer = ({
  showFeedbackButton,
  mode,
  orderId,
}: {
  showFeedbackButton?: boolean;
  mode?: SKU_CHANT_MODE;
  orderId?: string;
}) => (
  <>
    {showFeedbackButton && FEEDBACK_URL && (
      <>
        <Box my={5}></Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          stType="gradient"
          fullWidth
          component="a"
          href={getFeedbackUrl(mode, orderId)}
        >
          ให้คะแนนความพึงพอใจสำหรับการบริการครั้งนี้
        </Button>
      </>
    )}
    <Box my={5}></Box>
    <Typography variant="body2" color="textSecondary" align="center">
      * ทางร้านค้า อาจจะไม่ได้ส่งหลักฐานหลังจากที่ดำเนินการทันที
    </Typography>
    <Typography variant="body2" color="textSecondary" align="center">
      หากมีข้อสงสัย หรือต้องการสอบถามเพิ่มเติม กรุณาติดต่อที่อีเมล์{' '}
      <a href="mailto:hello@sattha.online">hello@sattha.online</a>
    </Typography>
  </>
);

function OrderDetailsWishVowFix(props: OrderDetailsProps) {
  const {
    isChantImageLoading = true,
    chantImageUrl,
    storeUploadImageList = [],
    showFeedbackButton,
    locationId,
    mode,
    locationHolyThingImageUrl,
    shippingData,
    shippingTrackingData,
    haveShippingData,
    orderInfo,
  } = props;
  const showNotifyLateOrder = LateAlertBoxUtils.shouldShowNotifyOrderLateAlert(orderInfo);
  return (
    <Box>
      {locationHolyThingImageUrl && (
        <Container maxWidth={'md'} disableGutters sx={{ paddingY: 3 }}>
          <ImageBox imageUrl={locationHolyThingImageUrl} hideDownloadButton />
        </Container>
      )}
      <Box my={4}></Box>
      <Container maxWidth={'sm'} disableGutters={false}>
        {isChantImageLoading && !chantImageUrl && <Loading />}
        {chantImageUrl && <ChantImageBox imageUrl={chantImageUrl} />}
        {showNotifyLateOrder && <NotifyLateOrder order={orderInfo} />}
        <WallpaperCampaign orderId={orderInfo.orderId} />
        <Box my={4} display="flex" flexDirection="column" gap={1}>
          <Typography variant={'h4'} mb={1}>
            ข้อมูลการสั่งซื้อ
          </Typography>
          <Typography variant={'h5'}>วันที่ต้องการทำพิธี</Typography>
          <Typography>{orderInfo.packageDate}</Typography>
          {showNotifyLateOrder && (
            <Typography variant="label">{`${'* วันดำเนินการอาจจะไม่ตรงเนื่องจากสถานการณ์โควิด'}`}</Typography>
          )}
        </Box>
        {haveShippingData && (
          <>
            <Box my={2}></Box>
            <ShippingDetailsBox {...shippingData!} {...shippingTrackingData} />
          </>
        )}
        <Box my={2}></Box>
        <ChantInstructions locationId={locationId} mode={mode} />
        <Box my={2}></Box>
        <Box my={5}></Box>
        <Divider />
        <StoreUploadedImagesBox
          imageList={storeUploadImageList}
          mode={mode}
          customSubtitle={`เราจะดำเนินการให้คุณในวันที่ ${orderInfo.packageDate}`}
          packageDate={orderInfo.packageDate}
          customerName={orderInfo.chantName}
        />
        <Footer showFeedbackButton={showFeedbackButton} orderId={orderInfo.orderId} />
      </Container>
    </Box>
  );
}

function OrderDetailsWallpaper(props: OrderDetailsProps) {
  const {
    isChantImageLoading = true,
    chantImageUrl,
    storeUploadImageList = [],
    showFeedbackButton,
    locationId,
    mode,
    locationHolyThingImageUrl,
    shippingData,
    shippingTrackingData,
    haveShippingData,
    orderInfo,
  } = props;
  let waitMessage = getChantText(mode)[TEXT_LABEL.UPLOADED_IMAGE_DESCRIPTION_2];
  if (orderInfo.locationId === STORE_LOCATIONS.RMS) {
    waitMessage =
      'วอลเปเปอร์ของคุณอยู่ในคิวแล้ว คุณจะได้รับวอลเปเปอร์ภายใน 7-14 วัน เราจะแจ้งคุณไปเมื่อวอลเปเปอร์ทำเสร็จแล้ว';
  }
  return (
    <Box>
      <Container maxWidth={'sm'} disableGutters={false}>
        <Box pt={2} />
        <PackageInfoForm orderInfo={orderInfo} />
        <WallpaperCampaign orderId={orderInfo.orderId} />
        <StoreUploadedImagesBox
          imageList={storeUploadImageList}
          mode={mode}
          packageDate={orderInfo.packageDate}
          customerName={orderInfo.chantName}
          customSubtitle={waitMessage}
        />
        <Box mt={8} mb={4} />
        <WallpaperSummaryCard orderInfo={orderInfo} />
        {haveShippingData && (
          <>
            <Box my={2}></Box>
            <ShippingDetailsBox {...shippingData!} {...shippingTrackingData} />
          </>
        )}
        <Box my={5}></Box>
        <Footer showFeedbackButton={showFeedbackButton} orderId={orderInfo.orderId} />
      </Container>
    </Box>
  );
}

function OrderDetailsSingleShipment(props: OrderDetailsProps) {
  const {
    storeUploadImageList = [],
    showFeedbackButton,
    mode,
    shippingData,
    shippingTrackingData,
    haveShippingData,
    orderInfo,
  } = props;
  return (
    <Box>
      <Container maxWidth={'sm'} disableGutters={false}>
        <Box pt={2} />
        <PackageInfoForm orderInfo={orderInfo} />
        <Box my={2}></Box>
        <OrderWaitBox orderInfo={orderInfo} />
        <Box my={2}></Box>
        <ShipmentSummaryCard orderInfo={orderInfo} />
        <Box my={5}></Box>
        <Footer showFeedbackButton={showFeedbackButton} orderId={orderInfo.orderId} />
      </Container>
    </Box>
  );
}

function OrderDetailsWreath(props: OrderDetailsProps) {
  const {
    isChantImageLoading = true,
    chantImageUrl,
    storeUploadImageList = [],
    showFeedbackButton,
    locationId,
    mode,
    locationHolyThingImageUrl,
    shippingData,
    shippingTrackingData,
    haveShippingData,
    orderInfo,
  } = props;
  return (
    <Box>
      <Container maxWidth={'sm'} disableGutters={false}>
        <Box pt={2} />
        <PackageInfoForm orderInfo={orderInfo} />
        <WallpaperCampaign orderId={orderInfo.orderId} />
        <StoreUploadedImagesBox
          imageList={storeUploadImageList}
          mode={mode}
          packageDate={orderInfo.packageDate}
          customerName={orderInfo.chantName}
        />
        <Box mt={8} mb={4} />
        <WreathSummaryCard orderInfo={orderInfo} />
        <Box my={5}></Box>
        <Footer showFeedbackButton={showFeedbackButton} orderId={orderInfo.orderId} />
      </Container>
    </Box>
  );
}

export default function OrderDetails(props: OrderDetailsProps) {
  const { mode } = props;
  if (isSingleShipmentOrder(props.orderInfo)) {
    return <OrderDetailsSingleShipment {...props} />;
  }
  if ([CHANT_MODE.WALLPAPER].includes(mode)) {
    return <OrderDetailsWallpaper {...props} />;
  }
  if ([CHANT_MODE.FORTUNE_TELLING, CHANT_MODE.FORTUNE_TELLING_LIVE].includes(mode)) {
    return <OrderDetailsFortuneTelling {...props} />;
  }
  if ([CHANT_MODE.WREATH].includes(mode)) {
    return <OrderDetailsWreath {...props} />;
  }
  return <OrderDetailsWishVowFix {...props} />;
}
