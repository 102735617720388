import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

export enum THEME_VARIANT {
  THAIRATH = 'THAIRATH',
  SATTHA = 'SATTHA',
}

const PRIMARY_COLOR = process.env.REACT_APP_THEME_PRIMARY_COLOR || '#00B900';
const FONT_FAMILY = [
  'Anuphan',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const defaultTheme = createTheme();

const initialSatthaTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    h1: {
      fontWeight: 'bold',
      fontSize: 48,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 36,
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 24,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: 1.5,
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 15,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 13,
    },
    body1: {
      fontSize: 15,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 15,
    },
    fontSize: 15,
  },
  palette: {
    primary: { main: PRIMARY_COLOR },
    background: {
      default: '#ffffff',
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 'bold',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: PRIMARY_COLOR,
        },
        colorSecondary: {
          '&$checked': {
            color: PRIMARY_COLOR,
          },
        },
      },
    },
  },
});

const thairathTheme = createTheme({
  palette: {
    ...initialSatthaTheme.palette,
    grey: {
      200: '#E5E5E5',
    },
    secondary: {
      main: '#8C46FF',
    },
    background: {
      default: PRIMARY_COLOR,
      paper: 'linear-gradient(180deg, #023E44 0%, #006949 100%)',
    },
  },
  typography: {
    ...initialSatthaTheme.typography,
    h2: {
      fontWeight: 650,
      fontSize: 32,
    },
    h4: {
      fontWeight: 650,
      fontSize: 20,
    },
  },
});

const satthaTheme = deepmerge(defaultTheme, initialSatthaTheme);
export { satthaTheme, thairathTheme };
