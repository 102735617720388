import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import getConfig from 'Libs/Config/utils';
import { THEME_VARIANT } from '../Theme/themeConfigs';
import { darken } from '@mui/material';
const config = getConfig();

const gradientButtonStyle =
  process.env.REACT_APP_THEME_GRADIENT_BUTTON ||
  'linear-gradient(314.47deg, #01C1A8 0%, #1DCE59 90.84%)';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: config.theme.variant === THEME_VARIANT.THAIRATH ? theme.spacing(13) : 2,
    border:
      config.theme.variant === THEME_VARIANT.THAIRATH
        ? `1px solid ${theme.palette.secondary.main}`
        : `1px solid ${theme.palette.primary.main}`,
    color:
      config.theme.variant === THEME_VARIANT.THAIRATH
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    '&:hover': {
      border:
        config.theme.variant === THEME_VARIANT.THAIRATH
          ? `1px solid ${darken(theme.palette.secondary.main, 0.3)}`
          : `1px solid ${darken(theme.palette.primary.main, 0.3)}`,
    },
  },
  gradientButton: {
    background: `${gradientButtonStyle};`,
    borderRadius: config.theme.variant === THEME_VARIANT.THAIRATH ? theme.spacing(13) : 2,
    color: 'white',
    '&:disabled': {
      color: `#969696`,
      background: '#E6E6E6',
    },
  },
}));

const STButton = (props) => {
  const classes = useStyles();
  const { stType, ...restProps } = props;
  if (stType === 'gradient') {
    return <Button className={classes.gradientButton} {...restProps} />;
  }
  return <Button className={classes.button} {...restProps} />;
};

export default STButton;
