import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import getConfig from 'Libs/Config/utils';
import { THEME_VARIANT } from 'Components/Base/Theme/themeConfigs';
const config = getConfig();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    margin: config.theme.variant === THEME_VARIANT.THAIRATH ? '0 auto' : theme.spacing(0),
    '&:hover': {
      borderRadius: theme.spacing(1),
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom:
      config.theme.variant === THEME_VARIANT.THAIRATH
        ? `1px solid ${theme.palette.grey[200]}`
        : 'none',
  },
}));

const useLogoStyles = makeStyles((theme) => ({
  logo: {
    minHeight: 27,
    maxHeight: config.theme.logoMaxHeight,
  },
}));

const Logo = () => {
  const classes = useLogoStyles();
  return <img src={config.theme.logo} className={classes.logo} alt="header logo" />;
};

export default function ApplicationBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <Logo />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
